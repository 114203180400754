<template>
  <div>
    <div
      class="order-card d-flex justify-content-between align-items-center radius-12 p-12 m-b-16"
    >
      <div class="order-info d-flex justify-content-start align-items-center">
        <div class="img-container radius-6 m-r-10">
          <img
            class="cursor-pointer"
            :src="wishlist.offer_image"
            :alt="wishlist.offer_name"
            @click="sendOfferId(wishlist.offer_id, wishlist.offer_name)"
          >
        </div>

        <div class="content">
          <h4
            class="bold-font fs-16 black-color-text title cursor-pointer"
            @click="sendOfferId(wishlist.offer_id, wishlist.offer_name)"
          >
            {{ wishlist.offer_name }}
          </h4>
          <p class="fs-14 light-gray-color-text m-y-10 description">
            {{ wishlist.offer_brief }}
          </p>
        </div>
      </div>

      <div class="order-action">
        <div
          class="badgs d-flex justify-content-end align-items-center gap-10 m-b-20"
        >
          <div class="p-y-4 p-x-8 radius-4 medium-font">
            <span
              class="fs-12 medium-font gray-color-text"
              style="text-decoration: line-through"
            >
              {{ wishlist.offer_value }} {{ $t('account.egp') }}
            </span>
          </div>

          <div class="black-color-bg p-y-4 p-x-8 radius-4">
            <span class="fs-12 medium-font white-color-text">
              {{ wishlist.actual_value }} {{ $t('account.egp') }}
            </span>
          </div>
        </div>

        <div
          class="actions fav-actions d-flex justify-content-end align-items-center gap-10"
        >
          <BaseButton
            :text="'account.moveToCart'"
            fixedWidth="140px"
            :is-medium="true"
            colorClass="white-color-text"
            bgColorClass="main-color-bg"
            @click.native="addToCart(wishlist)"
          />

          <BaseButton
            :text="'account.removeFormFav'"
            fixedWidth="140px"
            :is-medium="true"
            :style="{ border: '1px solid #F03F3F20' }"
            bgColorClass="white-color-bg"
            colorClass="red-color-text"
            @click.native="deletedFromFav(wishlist)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ToggleWishList from '@/services/profile'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  name: 'WishListCard',
  props: {
    lists: {
      type: Array,
      required: false,
      default: () => []
    },
    wishlist: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      counter: 1,
      payload: {}
    }
  },

  computed: {
    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    }
  },

  methods: {
    deletedFromFav (wishlist) {
      const payload = {
        app_version: '',
        store: '',
        token: this.getUserToken,
        offer_id: wishlist.offer_id
      }

      ToggleWishList.toggleWishList(payload)
        .then((res) => {
          const indexElement = this.lists.indexOf(wishlist)
          this.lists.splice(indexElement, 1)
        })
        .catch((err) => {})
    },

    getSingleOffer (id) {
      const payload = {
        app_version: '',
        store: '',
        offer_id: id
      }

      this.$store
        .dispatch('offers/getSingleOffer', payload)
        .then(() => {})
        .catch((err) => {})
    },

    addToCart (wishlist) {
      this.getSingleOffer(wishlist.offer_id)
      this.payload = {
        'items[0][offer_id]': wishlist.offer_id,
        'items[0][type_id]': this.$store.state.offers.type_price_id,
        'items[0][qty]': this.counter,
        'device_token': this.getUserIp
      }
      this.$store.dispatch('cart/addToCart', this.payload).then(() => {
        toast.success(this.$t('offers.addToCart'))
        this.deletedFromFav(wishlist)
      })
    },

    sendOfferId () {
      this.$emit('goToOffer', this.wishlist.offer_id, this.wishlist.offer_name)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './base-order-card.scss';
</style>
