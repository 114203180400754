<template>
  <div class="my-orders">
    <template v-if="!offerLoading && lists.length">
      <div>
        <WishListCard
          v-for="(list, index) in lists"
          :key="index"
          :wishlist="list"
          :lists="lists"
          @goToOffer="setOfferId"
        />
      </div>
    </template>
    <template v-if="offerLoading">
      <listOrderLoader
        v-for="item in 3"
        :key="item"
      />
    </template>
    <template v-else-if="!lists.length && !offerLoading">
      <h2 class="text-center mb-5">
        {{ errorMessage.html }}
      </h2>
      <div class="text-center">
        <img
          :src="errorMessage.image"
          alt=""
        >
      </div>
    </template>
    <pagination
      v-if="lists.length"
      v-model="page"
      :records="paginationNumberPages"
      :per-page="10"
      :options="{ chunk: 3 }"
      @paginate="goToCurrentIndex"
    />
  </div>
</template>

<script>
import GetProfileData from '../../../../services/profile'
import Pagination from 'v-pagination-3'
import listOrderLoader from '../../../partials/base-order-card/baseOrderLoader.vue'
import WishListCard from '../../../partials/base-order-card/WishListIndex.vue'

export default {
  name: 'FavoriteOffers',
  components: {
    Pagination,
    WishListCard,
    listOrderLoader
  },
  data () {
    return {
      errorMessage: {
        image: '',
        html: ''
      },
      lists: [],
      page: 1,
      offerLoading: true,
      paginationNumberPages: 0
    }
  },
  watch: {
    'lists.length' () {
      if (this.lists.length == 0) {
        this.getAllLists()
      }
    }
  },
  created () {
    this.getAllLists()
  },
  methods: {
    setOfferId (id, name) {
      const offerName = name.replace(/[ "" 25%]/g, '-')
      const path = {
        name: 'offer-details',
        params: { id, offerName }
      }
      if (this.$route.path !== path) this.$router.push(path)
    },

    goToCurrentIndex (page) {
      this.page = page
      this.offerLoading = true
      this.lists = []
      this.getAllLists()
    },

    getAllLists () {
      const payload = {
        app_version: '',
        store: '',
        token: this.getUserToken,
        page: this.page
      }
      this.offerLoading = true
      GetProfileData.getWishList(payload)
        .then((res) => {
          this.paginationNumberPages = res.data.count
          this.offerLoading = false
          this.lists = res.data.offers
        })
        .catch((err) => {
          this.errorMessage.html = err.response.data.data.message
          this.errorMessage.image = err.response.data.data.image
          this.offerLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
