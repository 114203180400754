<template>
  <div class="w-100">
    <FinishedOrders />
  </div>
</template>

<script>
import FinishedOrders from '@/components/profile/orders/favorite-offers/Index.vue'

export default {
  name: 'FinishedOrdersPage',
  components: { FinishedOrders }
}
</script>
